@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
.login-button {
  background-color: #185873;
  padding: 5px 25px;
  color: white;
  font-family: "Montserrat, sans-serif";
  font-size: 1.5rem;
  border-radius: 17px;
  cursor: pointer;
  border-color: #185873;
}
.login-button:hover {
  background-color: rgb(8, 66, 101);
  border-color: rgb(8, 66, 101);
}
