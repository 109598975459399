.login{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 20px;
}

.form{
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    padding: 50px;
    height: 150px;
    margin-top: 150px;
    margin-right: 50px;
}
.login-img{
    margin-left: 50px;
}
