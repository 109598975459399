.card {
  /*background: linear-gradient(to bottom, #1c6685, #238998);*/
  background-color: "#1c6685";
  margin: 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 5px;
  width: 248px;
  height: 100px;
  text-align: center;
  /* cursor: pointer; */
  border: 0.1px solid white;
  box-shadow: "0 0 10px rgba(0, 0, 0, 0.1)";
  cursor: pointer;
}

.card-rep {
  position: relative; /* Required for absolute positioning */
  background-color: #1c6685;
  border-radius: 8px;
  border: 0.1px solid white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 5px;
  text-align: center;
  width: calc(25% - 10px); /* Adjust the width for larger screens */
  min-width: 150px; /* Minimum width for small screens */
  min-height: 120px;
  display: flex;
  flex-direction: column; /* Stack card content vertically */
  cursor: pointer;
}

.card-hod {
  position: relative; /* Required for absolute positioning */
  background-color: #1c6685;
  border-radius: 8px;
  border: 0.1px solid white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 5px;
  text-align: center;
  width: calc(25% - 10px); /* Adjust the width for larger screens */
  min-width: 150px; /* Minimum width for small screens */
  min-height: 120px;
  display: flex;
  flex-direction: column; /* Stack card content vertically */
  cursor: pointer;
}

.card-content {
  padding: 10px;
}
.card-title {
  font-size: 0.75rem;
  margin: 0px;
  font-family: "Montserrat";
  color: #ffffff;
  /*margin-bottom: 10px;*/
}
.card-text {
  font-size: 0.5rem;
  line-height: 1.5;
  color: #ffffff;
  font-family: "Montserrat";
}
.grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
