.button-cls{
    border-radius:15px;
    background-color: #14475D;
    width:150px;
    height:30px;
    color:white;
    font-size: 16px;
    border-radius: 8px;
    font-family:'Montserrat';
    text-align: center;
    padding-top: 10px;
    cursor: pointer;
}