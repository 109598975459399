/*.ProgressBar {
    font-family: sans-serif;
    text-align: center;
  }
.progress-bar ul li:before {
    background: linear-gradient(to left, #c4c4c4 50%, #4db193 50%) !important;
  }
.progress-bar ul li span {
    background-color: #c4c4c4 !important;
    font-size: 0;
    width: 24px !important;
    height: 24px !important;
  }*/
/* Popup.css */
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: default;
}

.popup {
  background-color: #017d95;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  margin-left: 55px;
}
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.timeline-item {
  position: relative;
  padding: 20px;
  margin-bottom: 20px;
}

.timeline-item-content {
  display: flex;
  align-items: center;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ccc;
}

.line {
  width: 2px;
  height: 100%;
  background-color: #ccc;
}

.text {
  flex: 1;
  margin-left: 10px; /* Adjust spacing between circle and text */
  color: black;
  font-weight: bold;
}

.date {
  font-weight: bold;
}

h3 {
  margin: 0;
}

p {
  margin-top: 5px;
}
