@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
.navbar {
  margin: 0;
  position: fixed;
  width: 100%;
  top: 0;
  height: 30px;
  padding: 20px;
  background-color: #185873;
  /* //#035088; */
  display: flex;
  justify-content: space-between;
  border-radius: 0;
}
.logo {
  color: white;
  position: absolute;
  font-family: "Montserrat, sans-serif";
  font-size: 2rem;
  left: 4rem;
  font-weight: 100;
}

.logout {
  display: flex;
  position: fixed;
  right: 20px;
  width: 30px;
  padding-top: 5px;
  fill: white;
}
.logout:hover {
  fill: #cacaca;
}

.back {
  fill: white;
  position: absolute;
  left: 0.7rem;
  padding-top: 7.5px;
}

.back:hover {
  fill: #cacaca;
}
