.approval-button{
    padding: 5px;
    font-family: 'Junge';
    font-size: 1.5rem;
    /* box-shadow: 0px 4px 9px #4F667D; */
    border-radius: 17px;
    cursor: pointer;
    border-color: #035088;

}

.approval-button:hover{
    background-color: rgb(8, 66, 101);
    border-color: rgb(8, 66, 101);
}