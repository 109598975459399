/*.inactive{
    position: absolute;
    left: 0;
    top: 0;
    width: 0.3rem;
    height: 100%;
    background-color: rgb(9, 215, 6);
}
.active{
    position: absolute;
    left: 0;
    top: 0;
    width: 0.3rem;
    height: 100%;
    background-color: red;
}*/
body {
  min-height: 100vh;
}

/*
.card {
    /*background: linear-gradient(to bottom, #1c6685, #238998);
     background-color: '#1c6685';
      margin: 0;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      padding: 10px;
      margin: 5px; 
      width: 248px;
      height: 100px;
      text-align: center;
      cursor: pointer;
      border: 0.1px solid white;
      box-shadow: '0 0 10px rgba(0, 0, 0, 0.1)';
    }
    .card-content {
      padding: 10px;
    }
    .card-title {
      font-size: 0.75rem;
      margin:0px;
      font-family:'Montserrat';
      color:#FFFFFF;
      /*margin-bottom: 10px;
    }
    .card-text {
      font-size: 0.5rem;
      line-height: 1.5;
      color: #FFFFFF;
      font-family:'Montserrat';
    }
    .grid-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }*/

.card {
  position: relative; /* Required for absolute positioning */
  background-color: #1c6685;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 5px;
  width: calc(25% - 10px); /* Adjust the width for larger screens */
  min-width: 150px; /* Minimum width for small screens */
  height: 120px;
  display: flex;
  flex-direction: column; /* Stack card content vertically */
}

.card-content {
  flex: 1; /* Allow card text to take up remaining space */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Space out card title and text */
}

.card-title {
  font-size: 1rem; /* Increase font size for better readability */
  margin: 0;
  font-family: "Montserrat";
  color: #ffffff;
}

.card-text {
  font-size: 0.75rem; /* Increase font size for better readability */
  line-height: 1.5;
  color: #ffffff;
  font-family: "Montserrat";
}

.card-icon {
  position: absolute; /* Position the icon absolutely */
  bottom: 5px; /* Adjust the distance from the bottom */
  right: 5px; /* Adjust the distance from the right */
  background-color: #ffffff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1); /* Semi-transparent background */
  backdrop-filter: blur(2px); /* Apply blur effect to background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure pop-up is on top of other content */
}

.popup {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}
