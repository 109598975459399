.section-button{
    border-radius: 5px;
    background-color:#E2EBFC;
    padding: 10px 40px;
    color: #035088;
    font-family: 'Junge';
    font-size: 1.5rem;
    cursor: pointer;
    border:2px solid #035088;
}
.submit-button{
    position:fixed;
}
  